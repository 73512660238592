<template>
  <page-layout>
    <v-container>
      <v-row>
        <v-col md="6" lg="6" xl="12">
          <v-select
            v-model="selectedFirm"
            :items="firms"
            label="Firmalar"
            item-value="_id"
            item-text="name"
            outlined
            persistent-hint
            @change="changeFirm"
          ></v-select>
        </v-col>
      </v-row>
      <v-row v-if="exams.length > 0">
        <v-col md="6" lg="6" xl="12">
          <v-select
            v-model="selectedExam"
            :items="exams"
            label="Sınavlar"
            item-value="_id"
            item-text="name"
            outlined
            persistent-hint
            @change="changeExam"
          ></v-select>
        </v-col>
      </v-row>
      <v-row v-if="report.length > 0">
        <v-col
          offset-md="10"
          offset-lg="10"
          offset-xl="10"
          md="2"
          lg="2"
          xl="2"
        >
          <v-btn elevation="3" @click="exportExcel"> Excel'e Aktar </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <div>
      <v-data-table
        :headers="headers"
        :items="report"
        :loading="isLoading"
        class="elevation-1 mt-3"
      >
        <template v-slot:[`item.createdAt`]="{ item }">
          {{ formatDate(item.createdAt) }}
        </template>
        <template v-slot:[`item.finishedAt`]="{ item }">
          {{ formatDate(item.finishedAt) }}
        </template>
        <template v-slot:[`item._id`]="{ item }">
          <v-icon @click="onGeneratePdf(item)"> mdi-file-pdf-box </v-icon>
          <v-icon
            v-if="item.testType === 'bigfive'"
            @click="goToBigFiveHtml(item)"
          >
            mdi-alpha-h-box-outline
          </v-icon>
        </template>
      </v-data-table>
    </div>
  </page-layout>
</template>

<script>
import axios from '@axios';
import dateformat from 'dateformat';
import { json2excel } from 'js2excel';

import PageLayout from '@/components/PageLayout.vue';
export default {
  name: 'Rapor',
  components: { PageLayout },
  data: () => ({
    isLoading: true,
    firms: [],
    exams: [],
    report: [],
    headers: [
      {
        text: 'T.C.',
        value: 'idNumber',
        sortable: false
      },
      {
        text: 'İsim',
        value: 'name',
        sortable: false
      },
      {
        text: 'Soyisim',
        value: 'lastname',
        sortable: false
      },
      {
        text: 'Telefon',
        value: 'phone',
        sortable: false
      },
      {
        text: 'Test',
        value: 'testName',
        sortable: false
      },
      {
        text: 'Puan',
        value: 'point',
        sortable: false
      },
      {
        text: 'Seviye',
        value: 'level',
        sortable: false
      },
      {
        text: 'Açıklama',
        value: 'description',
        sortable: false
      },
      {
        text: 'Başlangıç',
        value: 'createdAt',
        sortable: false
      },
      {
        text: 'Bitiş',
        value: 'finishedAt',
        sortable: false
      },
      {
        text: 'Geçen Süre',
        value: 'elapsed',
        sortable: false
      },
      {
        width: '100px',
        text: 'İşlemler',
        value: '_id',
        sortable: false
      }
    ],
    selectedExam: '',
    selectedFirm: ''
  }),
  mounted() {
    this.listFirm();
  },
  methods: {
    listFirm() {
      axios.get('/list-firm').then((response) => {
        this.isLoading = false;
        const items = response.data.result;
        this.firms = items;
        this.firms.push({ name: 'boss', _id: 1 });
      });
    },
    listExam(firmId) {
      axios.get(`/list-exam/${firmId}`).then((response) => {
        this.isLoading = false;
        const items = response.data.result;
        this.exams = items;
      });
    },
    listReport() {
      axios.get(`report-exam-result/${this.selectedExam}`).then((response) => {
        this.isLoading = false;
        const items = response.data.result;
        this.report = items;
      });
    },
    changeExam(e) {
      this.isLoading = true;
      this.selectedExam = e;
      if (this.selectedExam) {
        this.listReport();
      }
    },
    changeFirm(e) {
      this.isLoading = true;
      this.selectedFirm = e;
      if (this.selectedFirm) {
        this.listExam(e);
      }
    },
    exportExcel() {
      try {
        json2excel({
          data: this.report,
          name: 'report-test-result',
          formateDate: 'yyyy/mm/dd'
        });
      } catch (e) {
        console.log(e);
        this.$toast.error(
          'Excel Aktarım Hatası.\nTablonun Dolu olduğuna emin olun'
        );
      }
    },
    formatDate: function (val) {
      return dateformat(new Date(val), 'yyyy-mm-dd HH:MM:ss');
    },
    onGeneratePdf(item) {
      let pdfUrl = 'generate-pdf-candidate-result';
      let testname = '';
      switch (item.testType) {
        case 'advancedenglish':
          pdfUrl = 'generate-advanced-english-pdf-candidate-result';
          testname = 'taleon_advanced_english_test';
          break;
        case 'english':
          pdfUrl = 'generate-english-pdf-candidate-result';
          testname = 'taleon_general_english_test';
          break;
        case 'german':
          pdfUrl = 'generate-german-pdf-candidate-result';
          testname = 'taleon_german_profiency_test';
          break;
        case 'french':
          pdfUrl = 'generate-french-pdf-candidate-result';
          testname = 'taleon_french_profiency_test';
          break;
        case 'spanish':
          pdfUrl = 'generate-spanish-pdf-candidate-result';
          testname = 'taleon_spanish_profiency_test';
          break;
        case 'russian':
          pdfUrl = 'generate-russian-pdf-candidate-result';
          testname = 'taleon_russian_profiency_test';
          break;
        case 'chinese':
          pdfUrl = 'generate-chinese-pdf-candidate-result';
          testname = 'taleon_chinese_profiency_test';
          break;
        case 'bigfive':
          pdfUrl = 'generate-bigfive-pdf-candidate-result/' + item.caId;
          testname = 'taleon_select';
          break;
        case 'generalability':
          pdfUrl = 'generate-generalability-pdf-candidate-result';
          testname = 'taleon_genel_yetenek_testi';
          break;
        default:
          break;
      }
      axios
        .post(pdfUrl, item, {
          responseType: 'arraybuffer',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/pdf'
          }
        })
        .then((response) => {
          const link = document.createElement('a');
          link.href = URL.createObjectURL(new Blob([response.data]));
          link.download = `${item.name}_${item.lastname}_${testname}.pdf`;
          link.click();
          URL.revokeObjectURL(link.href);
        });
    },
    goToBigFiveHtml(item) {
      this.$router.push(`/result-taleon-select/${item.caId}`);
    }
  }
};
</script>

<style lang="scss"></style>
